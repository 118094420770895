<template>
  <div class="visitsubSurveyListDetail">
    <div class="taskList">
      <div class="taskList-item flex" v-for="(item, index) in taskList" :key="index"  @click="gotoFinished(item)">
        <div>{{item.taskName}}</div>
        <div class="button">查看详情</div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveMyTask, getprobeSubTaskList } from '@/utils/api'
import common from '@/utils/common'
import {selectSubTaskList} from "../../utils/api";
import {uwStatisticAction} from "../../utils/util";
export default {
  data () {
    return {
      taskList: null,
      siteInfo: null,
      disableFlag: true
    }
  },
  watch:{
    taskList:{
      handler(val){
        let flag = false
        for(let obj of val){
          if(!obj.hasFinished){
            flag = true
            return
          }
        }
        this.disableFlag = flag
      },
      deep: true
    }
  },
  mounted () {
    if(this.$route.query.areaCode){
      this.toGetprobeSubTaskList()
    }
    if(this.$route.query.taskNo){
      this.toSelectSubTaskList()
    }
    uwStatisticAction('/visitsubSurveyListDetail','走访调研子任务列表查看只读')
  },
  methods: {
    toSelectSubTaskList() {
       selectSubTaskList({taskNo: this.$route.query.taskNo}).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.taskList = res.data.data
          }
        }
      })
    },
    toGetprobeSubTaskList() {
      getprobeSubTaskList({areaCode: this.$route.query.areaCode}).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.taskList = res.data.data
          }
        }
      })
    },
    gotoFinished(item){
      this.$router.push({ name: 'surveyDetail', query:{taskCode: 'probe', taskNo: this.$route.query.taskNo, subTaskCode: item.taskCode, taskId: item.id}})
    },
  }
}
</script>

<style lang="less" scoped>
.visitsubSurveyListDetail {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
  background: #F7F8F9;
  .flex{
    display: flex;
  }
  .taskList{
    background: #fff;
    // margin-top: 0.3rem;
  }
  .taskList-item{
    height: 0.95rem;
    border-bottom: 0.01rem solid #F7F8F9;
    padding: 0 0.3rem;
    font-size: 0.3rem;
    color: #333;
    justify-content: space-between;
    align-items: center;
    .button{
      text-align: center;
      width: 1.5rem;
      height: 0.5rem;
      line-height: 0.5rem;
      font-size: 0.24rem;
      border-radius: 0.25rem;
      background: #3F7C53;
      color: #fff;
      border:0;
      font-weight: normal;
    }
    .button-finished{
      background: #fff;
      color: #3F7C53;
      border: 0.02rem solid #3F7C53;
    }
  }
  .nextBtn {
    width: 100%;
    padding: 0.24rem;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .disableFlag{
    opacity: 0.5 !important;
  }
  .btn-active {
    height: 0.98rem;
    line-height: 0.98rem;
    text-align: center;
    background: #3f7c53;
    border-radius: 0.08rem;
    font-size: 0.36rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 100%;
    opacity: 1;
  }
}
</style>